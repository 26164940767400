import { ActionType, getType } from "typesafe-actions";
import { put, takeLatest, call, select, all } from "redux-saga/effects";
import { errorActions } from "../actions";
import { editNewsActions } from "../actions/edit-news.actions";
import { News } from "../types/news/News";
import { newsService } from "../services/news.service";
import { Company } from "../types/amr-pipeline/models/Company";
import { routes } from "../constants";
import { AppState } from "../types/state/AppState";
import { EditNewsTabType } from "../types/news/enums/EditNewsTabType";
import { constants } from "../components/management/news/constants";
import { formatUtils } from "../utils";
import moment from 'moment';
import { history } from "../history";
import { amrCompaniesService } from '../services/amr-companies.service';
import { HavingDealType } from '../types/amr-pipeline/enums/HavingDealType';

function alignNewsDateTime(newsDateTime: Date, initialNewsDateTime?: Date) {
    const newsDate = moment.utc(newsDateTime);
    const currentDate = moment.utc();

    if (newsDate.isSame(currentDate, 'day')) {
        return new Date();
    }

    if (initialNewsDateTime && moment.utc(initialNewsDateTime).isSame(newsDate, 'day')) {
        return initialNewsDateTime;
    }

    return moment(newsDateTime).endOf('day').toDate();
}

function* watchGetTotalCount() {
    const searchTerm: string = yield select(
        (s: AppState) => s.editNews.searchTerm
    );
    try {
        const totalCount: number = yield call(newsService.getNewsTotalCount, {
            searchTerm:
                searchTerm.length > constants.maxSearchLengthToIgnore
                    ? searchTerm
                    : "",
        });

        yield put(editNewsActions.getTotalCountResult(totalCount));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchInit(action: ActionType<typeof editNewsActions.init>) {
    const offset: number = yield select((s: AppState) => s.editNews.offset);
    const { initialReferenceName } = action.payload;
    try {
        const [managers, arrangers, newsList]: [
            managers: Company[],
            arrangers: Company[],
            newsList: News[]
        ] = yield all([
            call(amrCompaniesService.getManagersList, HavingDealType.CloManagers),
            call(amrCompaniesService.getArrangersList, true),
            call(newsService.getNews, {
                count: constants.newsLoadCount,
                offset,
            }),
        ]);

        let initialNews = newsList[0];

        if (initialReferenceName) {
            initialNews = yield call(
                newsService.getNewsDetails,
                initialReferenceName
            );
        }

        yield call(watchGetTotalCount);
        yield put(editNewsActions.setInitialNews(initialNews));
        yield put(editNewsActions.getInitResult(newsList, managers, arrangers));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchLoadMoreNews(
    action: ActionType<typeof editNewsActions.loadMoreNewsList>
) {
    const offset: number = yield select((s: AppState) => s.editNews.offset);
    const { searchTerm } = action.payload;
    try {
        const newsList: News[] = yield call(newsService.getNews, {
            count: constants.newsLoadCount,
            offset,
            searchTerm,
        });

        yield put(editNewsActions.loadMoreNewsListResult(newsList));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchSearchNews(
    action: ActionType<typeof editNewsActions.searchNewsList>
) {
    const { searchTerm } = action.payload;
    try {
        const newsList: News[] = yield call(newsService.getNews, {
            count: constants.newsLoadCount,
            offset: 0,
            searchTerm,
        });
        yield call(watchGetTotalCount);
        yield put(editNewsActions.setInitialNews(newsList[0]));
        yield put(editNewsActions.searchNewsListResult(newsList));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchGetNewsDetails(
    action: ActionType<typeof editNewsActions.getNewsDetails>
) {
    try {
        const { referenceName } = action.payload;

        const newsDetails: News = yield call(
            newsService.getNewsDetails,
            referenceName
        );

        yield put(editNewsActions.getNewsDetailsResult(newsDetails));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchCreateOrUpdateNews(
    action: ActionType<typeof editNewsActions.createOrUpdateNews>
) {
    const initialNewsDateTime: Date | undefined = yield select(
        (state: AppState) => state.editNews.newsDetails?.newsDateTime
    );

    const { newsData } = action.payload;

    const newsDataToSave = {
        ...newsData,
        externalSourceLink: formatUtils.formatUrlWithProtocol(
            newsData.externalSourceLink
        ),
        transactionLink: formatUtils.formatUrlWithProtocol(
            newsData.transactionLink
        ),
        newsDateTime: alignNewsDateTime(newsData.newsDateTime, initialNewsDateTime),
    };

    try {
        const newReferenceName: string | undefined = yield call(
            newsService.createOrUpdateNews,
            newsDataToSave
        );

        yield call(watchGetTotalCount);

        const newsList: News[] = yield call(newsService.getNews, {
            offset: 0,
            count: constants.newsLoadCount
        });

        yield put(
            editNewsActions.setInitialNews({
                ...newsDataToSave,
                ...(newReferenceName && { referenceName: newReferenceName }),
            })
        );

        yield put(editNewsActions.updateNewsList(newsList));

        yield call(history.push, routes.manageNewsUrl(newReferenceName, EditNewsTabType.view));
        if (newReferenceName) {
            yield put(editNewsActions.getNewsDetails(newReferenceName));
        }
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchDeleteNews(
    action: ActionType<typeof editNewsActions.deleteNews>
) {
    const { referenceName } = action.payload;
    const initialNews: News = yield select(
        (s: AppState) => s.editNews.initialNews
    );
    const newsList: News[] = yield select((s: AppState) => s.editNews.newsList);
    const offset: number = yield select((s: AppState) => s.editNews.offset);

    const isInitialNews = referenceName === initialNews.referenceName;

    try {
        yield call(newsService.deleteNews, referenceName);
        const updatedList: News[] = yield call(newsService.getNews, {
            offset: 0,
            count: offset,
        });

        const indexOfDeletedItem = newsList.findIndex(
            (n) => n.referenceName === referenceName
        );

        const nextListItem = updatedList[indexOfDeletedItem];
        const firstListItem = updatedList[0];

        const newActiveItem =
            isInitialNews || !nextListItem ? firstListItem : nextListItem;

        yield put(editNewsActions.resetNewsDetails());

        if (isInitialNews) {
            yield put(editNewsActions.setInitialNews(firstListItem));
        }

        if (firstListItem) {
            yield put(editNewsActions.updateNewsList(updatedList));
        } else {
            yield put(editNewsActions.reset());
        }

        yield call(
            history.push,
            newActiveItem
                ? routes.manageNewsUrl(
                    newActiveItem.referenceName,
                    EditNewsTabType.view
                )
                : routes.manageNewsPage
        );

        yield call(watchGetTotalCount);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchEditNews() {
    yield takeLatest(getType(editNewsActions.init), watchInit);
    yield takeLatest(
        getType(editNewsActions.loadMoreNewsList),
        watchLoadMoreNews
    );
    yield takeLatest(getType(editNewsActions.searchNewsList), watchSearchNews);
    yield takeLatest(
        getType(editNewsActions.getNewsDetails),
        watchGetNewsDetails
    );
    yield takeLatest(
        getType(editNewsActions.createOrUpdateNews),
        watchCreateOrUpdateNews
    );
    yield takeLatest(getType(editNewsActions.deleteNews), watchDeleteNews);
}
